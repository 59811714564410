<!--
    @name: TopoList
    @description：TopoList
    @author: ZengWei
    @date: 2022-06-17 11:46
-->
<template>
  <div class="topo-list">
    <div class="list-wrapper">
      <div
        class="list-item"
        v-for="item in topoData"
        :key="item.id"
        :class="{active: topoGraphId === item.id}"
        @click="activeItem(item.id)">
        <div class="title">
          <i class="iconfont iconyingyong"></i>

          <template v-if="item.name.length>12">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <span class="title-name">{{ item.name }}</span>
            </el-tooltip>
          </template>
          <template v-else>
            <span class="title-name">{{ item.name }}</span>
          </template>
        </div>
        <div class="action">
          <span>任务：{{taskNum(item.json)}}</span>
          <el-dropdown @command="handleCommand($event,item)">
            <span class="el-dropdown-link">
              <i class="iconfont iconbimgis_gengduo"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="edit">修改</el-dropdown-item>
              <el-dropdown-item command="del">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown,DropdownMenu,DropdownItem,Tooltip } from 'element-ui';
import eventBus from "@/plugins/eventBus";
export default {
  name: "TopoList",
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    'el-tooltip': Tooltip,
  },
  props: ['topoData'],
  data() {
    return {
      topoGraphId: 0
    }
  },
  computed:{
    taskNum(){
      return function (json){
        if(json){
          const taskNode = json.cells.filter(item=>['task'].includes(item.shape))
          return taskNode.length
        }
        return 0
      }
    }
  },
  mounted() {
    if(this.topoData.length){
      this.activeItem(this.topoData[0].id)
    }
  },
  methods: {
    activeItem(id){
      this.topoGraphId = id
      this.$emit('checked',id)
      eventBus.$emit('active-graph',id)
    },
    handleCommand(event,row){
      if(event === 'del') {
        this.$emit('on-trigger','del',row)
      } else {
        this.$emit('on-trigger','add',event,row)
      }
    },
  },
}
</script>

<style lang="less" scoped>
  .active{
    background-color: #f0f7ff;
  }
  .topo-list{

    .list-wrapper{
      margin: 10px 0;

      .list-item{
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;

        .title{
          width: 68%;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
          .title-name{
            padding-left: 10px;
          }
        }

        &:hover{
          background-color: #f0f7ff;
        }
      }

    }
  }
</style>
