<!--
 * @Description: dsf
 * @Author: luocheng
 * @Date: 2021-03-30 09:55:10
 * @LastEditors: luocheng
 * @LastEditTime: 2021-05-31 13:54:02
-->
<template>
  <div ref="chartDOM">
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { addListener, removeListener } from "resize-detector";
import { debounce } from '@/utils/tools';

export default {
  props: {
    option: {
      type: Object,
      default: () => {}


    },
    needDebounce : {
      type : Boolean,
      default : true
    },
    // 自动刷新
    autoRefresh: {
      type: Boolean,
      required: false,
      default: false
    },
    // 自动刷新间隔
    during: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      // 自动刷新的定时器
      interval: null
    }
  },
  watch: {
    option(val) {
      this.echart.setOption(val);
    }
  },
  created() {
    if(this.needDebounce) {
      this.resize = debounce(this.resize, 300, true); // 防抖处理
    }
  },
  mounted() {
    var _this = this
    this.renderChart();
    addListener(this.$refs.chartDOM, this.resize);
      // 监听echart加载
    this.echart = echarts.init(this.$refs.chartDOM);
    this.echart.on('click',function (param) {
        _this.$emit('echart-item-click',param)
    })
    if (this.autoRefresh) {
      if (this.interval) {
        clearTimeout(this.interval);
      }
      this.setRefresh();
    }
  },
  methods: {
    resize() {
      this.echart.resize();
    },
    clear() {
      this.echart.clear();
    },
    renderChart() {
      this.echart = echarts.init(this.$refs.chartDOM);
      this.echart.setOption(this.option);
    },
    // 自动刷新
    setRefresh() {
      if (!this.autoRefresh) return;
      this.interval = setTimeout(() => {
        if (!this.echart || !this.echart.dispose) {
          clearTimeout(this.interval);
          this.setRefresh();
          return;
        }
        // 刷新
        this.echart.dispose(); // 释放图表实例
        setTimeout(() => {
          this.echart = echarts.init(this.$refs.chartDOM);
          this.echart.setOption(this.option);
        }, 0);
        this.setRefresh();
      }, this.during || 10000);
    }
  },
  beforeDestroy() {
    removeListener(this.$refs.chartDOM, this.resize);
    this.echart.dispose(); // 释放图表实例
    this.echart = null;
  },
};
</script>
