<!--
 * @Description: 页面描述
 * @components: 构件
 * @componentDesc: 描述
 * @props: prop参数
-->
<template>
  <div class="itemTitle bg-none">
    <i style="margin:0" class="iconinname iconfont" @click="handel(titleData.triggerEvent)"
       :class="titleData.icon"></i>
    <span class="titleName">{{ titleData.name }}</span>
    <!-- <span class="float-right">
  <span :class="boolArr[i]?'activeIcon':''" v-for="(val,i) in titleData.action" :key="i"> -->
    <!-- <Tooltip  v-if="val.name&&val.name!=''" placement="bottom" always :content="val.name" transfer-class-name="title-tip"> -->
    <!-- <span v-if="(val.name&&val.name!='')">
        <i v-if="module==''||(module!=''&&moduleAccess(module,val.accessField))||val.triggerEvent=='close'"
           class="iconfont" :class="val.icon" :style="{color:'colorIcon' in val ? val.colorIcon : ''}"
           @click="handel1(val.triggerEvent,i)"></i>
    </span>
    <i v-else class="iconfont" :class="val.icon" @click="handel(val.triggerEvent)"></i>
  </span> -->
    <!-- </span> -->
  </div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import {mapGetters} from "vuex";

export default {
  name: "itemTitle",
  data() {
    return {
      boolArr: [],//点击添加背景色
      show: true
    }

  },
  computed: {
    ...mapGetters([
      'themeData'
    ])
  },
  components: {},
  props: {
    titleData: {
      type: [Object, Array]
    },
    module: {
      type: [String, Number],
      default: ''
    }

  },
  methods: {
    /**
     * @desc: 方法描述
     * @param: 参数名 参数描述
     * @return: null
     */
    handel(data) {
      this.$emit('triggerEvent', data)
    },
    /**
     * @desc: 方法描述
     * @param: 参数名 参数描述
     * @return: null
     */
    handel1(data, i) {
      this.$emit('triggerEvent', data)
      let arr = JSON.parse(JSON.stringify(this.boolArr))
      arr = arr.map((item) => {   //  有同事指出应该声明一个新变量来存储map的结果，这个建议我认为是对的。
        item = false
        return item;
      });
      let old = this.boolArr[i]
      this.$nextTick(() => {
        this.boolArr = arr
      })
      if (this.titleData.action && this.titleData.action[i] && this.titleData.action[i].name != '关闭' && this.titleData.action[i].name != '删除' && this.titleData.action[i].name != '设置') {
        this.$nextTick(() => {
          arr[i] = !old
          // console.log(this.boolArr,2222)
        })
      }
    },
    /**
     * @desc: 方法描述
     * @param: 参数名 参数描述
     * @return: null
     */
    clearAll() {
      this.boolArr = []
      this.titleData.action.forEach(() => {
        this.boolArr.push(false)
      })
      this.$forceUpdate()
    }
  },
  mounted() {
    eventBus.$on("clearActive", (val) => {
      if (val) {
        this.boolArr = []
      }
    })
    if (this.titleData.action.length != 0) {
      this.titleData.action.forEach((item) => {
        if (item.active != undefined && item.active) {
          this.boolArr.push(true)
        } else {
          this.boolArr.push(false)
        }

      })
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/views/topology/index.less";
@color: #38A7DE;

.float-right {
  float: right
}

.itemTitle {
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  border-bottom: 1px solid #f2f4f5;

  i {
    font-size: 16px;
    margin-left: 12px;
    vertical-align: middle;
    color: @color;

  }

  .iconinname {
    font-size: 27px;
    font-weight: 600;
  }

  .iconinname.iconsaidi-daohang-shezhi {
    font-size: 20px !important;
  }

  .iconxiangzuojiantou1 {
    font-size: 20px;
  }

  .iconshaixuan {
    font-size: 27px !important;
  }

  .iconshanchu2 {
    font-size: 20px !important;
    color: @color !important;
  }

  .titleName {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    margin-left: 5px;
    vertical-align: middle;
    color: @color;

  }

  .activeIcon {
    i {
      font-size: 12px !important;
      padding: 2px;
      border-radius: 3px;
      background: @color !important;
      color: #fff !important;
    }
  }
}
</style>
<style>
.title-tip.ivu-tooltip-popper {
  font-size: 12px !important;
  z-index: 2010 !important;
}

</style>
