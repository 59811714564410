<template>
  <div class="mainTopo">
    <div class="left">
      <TopoLeft></TopoLeft>
    </div>
    <div class="right">
      <Topology></Topology>
    </div>
  </div>
</template>

<script>
import TopoLeft from '@/views/topology/components/TopoLeft'
import Topology from '@/custom-component/topography/topology'

export default {
  components: {TopoLeft, Topology},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
  },
  mounted() {
  }
};
</script>
<style lang="less" scoped>
.mainTopo {
  display: flex;
  width: 100%;
  height: 100%;

  .left{
    width: 280px;
  }
  .right{
    flex: 1;
  }
}
</style>
