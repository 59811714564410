<!--
 * @Description: 页面描述
 * @components: 构件
 * @componentDesc: 描述
 * @props: prop参数
-->
<template>
  <div class="echart-wrapper">
    <div class="echart-content" v-for="(item,index) in optionArray" :key="'item'+index">
      <Echart
        ref="echartOption"
        :option="item.option"
        class="echart-content-item"
        :style="{height: `${item.height}px`,width:'100%'}"
      />
    </div>
  </div>
</template>

<script>

import * as echarts from 'echarts';
import Echart from "@/components/echart/echart";

export default {
  components: {
    Echart
  },
  props: {
    fenxiData: {
      type: Array,
    }
  },
  data() {
    return {
      echartOption: {},
      seriesData: [
        {name: '严重', value: '0.2'},
        {name: '中等', value: '0.1'},
        {name: '一般', value: '0.4'},
      ],
      optionArray: []
    }
  },
  watch: {
    fenxiData: {
      handler: function (newVal) {
        if (newVal && newVal.length) {
          this.getOptionArray(newVal)
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    /**
     * @desc: 方法描述
     * @param: 参数名 参数描述
     * @return: null
     */
    getOptionArray(newVal) {
      let data = []
      let obj = {}
      let height = 0
      let option = {}
      newVal.map(item => {
        height = 0
        obj = {}
        option = {}
        const {title, yAxisData, seriesData, colorList} = item
        option = this.theCrossBarChart(title, yAxisData, seriesData, colorList)
        if (seriesData && seriesData.length) {
          height = seriesData.length * 55 + 10
        }
        obj = {
          option,
          height
        }
        data.push(obj)
      })
      this.optionArray = data
    },
    /**
     * @desc: 方法描述
     * @param: 参数名 参数描述
     * @return: null
     */
    theCrossBarChart(title, yAxisData, seriesData, colorList) {
      return {
        title: {
          text: title,
          x: "left",
          textStyle: {
            fontSize: 14,
            fontWeight: 600,
            color: '#25282e'
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line"
          }
        },
        grid: {
          left: 0,
          right: 10,
          bottom: 0,
          top: 35,

          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          splitLine: {
            show: false //去掉网格线
          },
          show: false // 隐藏坐标轴和刻度尺
        },
        yAxis: {
          type: "category",
          data: yAxisData,
          splitLine: {
            show: false
          },
          axisTick: {
            // 隐藏刻度线
            show: false
          },
          axisLine: {
            // 隐藏坐标轴
            show: false,

          },
          axisLabel: {
            textStyle: {
              color: "#25282e",
              fontSize: 14
            },
            margin: 8
          },

        },
        series: [{
          type: "bar",
          itemStyle: {
            normal: {
              color: function (params) {

                // let colorList = ["#0C44B3", "#417BEF", "#81A7F2"];
                // console.log(colorList[params.dataIndex] ? colorList[params.dataIndex] : colorList[0])
                return colorList[params.dataIndex] ? colorList[params.dataIndex] : colorList[0];
              },
              label: {
                show: true,
                position: "right",
                formatter: "{c}"
              }
            }
          },
          barWidth: 12,
          // barCategoryGap:26,
          data: seriesData,

        }]
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      let _this = this;
      for (let index in this.fenxiData) {
        let title = this.fenxiData[index].title;
        if (title == '任务状态' || title == '预警情况') {
          let chart = echarts.init(this.$refs.echartOption[index].$refs.chartDOM);
          chart.on("click", (param) => {
            let type = 1;
            title == '任务状态' ? type = 1 : type = 2
            let query = {name: param.data.name, type: type};
            _this.$emit('filter-diagram', query)
          });
        }
      }
    });
  },
}
</script>
<style lang="less" scoped>
.echart-wrapper {
  width: 100%;
}

.echart-content {
  padding: 20px 15px;
  border-bottom: 1px solid #eaebed;
}
</style>
