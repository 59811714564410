<!--
 * @Description: 页面描述
 * @components: 构件
 * @componentDesc: 描述
 * @props: prop参数
-->
<template>
  <el-row class="new-tab-com">
    <el-col
      :span="24/tabs.length"
      v-for="(item, index) in tabs"
      :key="index">
      <p :class="acTab[itemKey] == item[itemKey]?'tab-com tabActive':'tab-com tab-color'"
         @click="activeChange(item)">{{ item.name }}</p>
    </el-col>
  </el-row>
</template>

<script>

export default {
  components: {},
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    acTab: {
      type: Object,
      // default:'tab-1'
    },
    itemKey: {
      type: [String, Number],
      default: 'key'
    },
  },
  data() {
    return {}
  },
  methods: {
    /**
     * @desc: 方法描述
     * @param: 参数名 参数描述
     * @return: null
     */
    activeChange(item) {
      this.$emit('active-tab', item)
    }
  },
  mounted() {

  },
}
</script>
<style lang="less" scoped>
@color: #38A7DE;

.new-tab-com {
  border: 1px solid @color;
  border-radius: 4px;
  margin: 0 16px;
}

.tab-com {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  cursor: default;
}

.tab-color {
  color: @color;
}

.tabActive {
  background: @color;
  color: white;
}
</style>
